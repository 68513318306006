// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { CameraDefRow } from '../typings';

const data = [
  {
    "id": "overview",
    "detachControl": false,
    "target": [
      0,
      -0.15,
      0
    ],
    "alpha": 0.5,
    "beta": 1.6,
    "radius": 4,
    "desktopRadius": 5,
    "fov": 0.471238898,
    "width": 0,
    "height": 0
  },
  {
    "id": "front",
    "detachControl": true,
    "target": [
      0,
      -0.15,
      0
    ],
    "alpha": 0.5,
    "beta": 1.6,
    "radius": 4,
    "desktopRadius": 4,
    "fov": 0.471238898,
    "width": 1200,
    "height": 630
  },
  {
    "id": "thumbnail",
    "detachControl": true,
    "target": [
      0,
      -0.15,
      0
    ],
    "alpha": 0.5,
    "beta": 1.6,
    "radius": 4,
    "desktopRadius": 4,
    "fov": 0.471238898,
    "width": 2048,
    "height": 1024
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<CameraDefRow[]>> = data;

export type CameraDef = typeof typed[number];

export const CAMERA_DEF_INDEX_KEY = "id";
export type CameraDefIndexKey = "id";
export type CameraDefId = CameraDef["id"];

let i = 0;
export const CAMERA_DEF_DICT = {
  "overview": typed[i++],
  "front": typed[i++],
  "thumbnail": typed[i++]
} as const;

export { typed as CAMERA_DEFS };
