import React from 'react'
import { Provider, useSelector } from 'react-redux'
import WebFont from 'webfontloader'

import Layout from '~rawlings/client/components/Layout'
import { layoutModeSelector } from '~p/client/common/selectors'

import '~rawlings/client/components/Main.css'

import Render3d from '../../render3d/Render3d'

WebFont.load({
  google: {
    families: ['Open Sans:300,400,600,700'],
  },
})

const getProductInfo = (nodes, recipeId) => ({
  summarySections: [],
  title: nodes['calc.sku'].value,
  priceWithCurrency: nodes['calc.priceWithCurrency'].value,
  configurationId: recipeId,
})

const ConnectedLayout = () => {
  const layoutMode = useSelector(layoutModeSelector)

  return (
    <Layout
      Renderer={<Render3d />}
      getProductInfo={getProductInfo}
      isScrollMenuLayout={layoutMode !== 'mobile'}
    />
  )
}

const Root = ({ store }) => (
  <Provider store={store}>
    <ConnectedLayout />
  </Provider>
)

export default Root
